<template>
  <v-container fluid class="pa-8">
    <!-- <v-row>
      <v-sheet
        outlined
        style="width: 100%"
        class="py-4 pl-2"
        color="primary"
        dark
      >
        <span>{{ $t("name") }}: </span>
        <span>{{ identityName }}</span>
      </v-sheet>
    </v-row> -->
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-row class="d-flex flex-row align-center py-4">
      <v-btn
        small
        color="primary"
        v-on:click="add_click()"
        v-if="status != statusValues.add && status != statusValues.edit"
        >{{ $t("add_button") }}</v-btn
      >
      <GsGeneralForm
        :config="formConfig"
        :cancel="cancel_click"
        :save="save_click"
        v-if="status == statusValues.add || status == statusValues.edit"
      />
      <v-spacer />
    </v-row>
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="profiles"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            sort-by="scope"
            item-key="profileId"
          >
            <template v-slot:top>
              <v-card class="pa-2 d-flex flex-row-reverse" flat>
                <v-text-field
                  class="col-sm-2"
                  v-model="search"
                  append-icon="mdi mdi-search"
                  :label="$t('search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card>
            </template>
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                <GsIdentityProfileDetails v-model="profile" />
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mr-2"
                small
                color="error"
                v-on:click="delete_click(item)"
                >{{ $t("delete_button") }}</v-btn
              >
              <v-btn small color="primary" v-on:click="edit_click(item)">{{
                $t("edit_button")
              }}</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsIdentityProfileDetails from "../components/GsIdentityProfileDetails";
import GsGeneralForm from "@/components/GsGeneralForm";
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "IdentityProfilesPage",
  components: {
    GsIdentityProfileDetails,
    GsGeneralForm,
    GsNavButtons
  },
  data() {
    return {
      search: "",
      profiles: [],
      expanded: [],
      singleExpand: true,
      status: 0,
      statusValues: {
        browse: 0,
        add: 1,
        edit: 2
      },
      profile: null,
      formConfig: {},
      identityId: null
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("scope"),
          align: "left",
          sortable: true,
          value: "scope"
        },
        { text: this.$t("uri"), sortable: false, value: "uri" },
        { text: this.$t("actions"), sortable: false, value: "actions" },
        { text: "", value: "data-table-expand" }
      ];
    }
  },
  watch: {
    "$route.params.identityId": {
      immediate: true,
      handler(v) {
        this.identityId = v;
        this.init();
      }
    }
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
      this.profiles = await this.getIdentityProfiles(this.identityId);
    },
    async add_click() {
      let profile = {
        scope: "",
        uri: ""
      };
      this.formConfig = {
        url: this.$store.state.idp_api_url,
        path: "identities/{identityId}/profiles:POST",
        map: {
          identityId: this.identityId
        },
        mode: "add",
        initData: profile
      };
      this.status = this.statusValues.add;
      this.expanded = [];
    },
    async edit_click(profile) {
      this.formConfig = {
        url: this.$store.state.idp_api_url,
        path: "identities/{identityId}/profiles/{profileId}:PATCH",
        map: {
          identityId: this.identityId,
          profileId: profile.profileId
        },
        mode: "edit",
        initData: profile
      };
      this.status = this.statusValues.edit;
      this.expanded = [];
    },
    async cancel_click() {
      this.status = this.statusValues.browse;
    },
    async save_click() {
      this.status = this.statusValues.browse;
      await this.init();
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.scope);
      if (!result) return;

      await callAPI({
        url:
          this.$store.state.idp_api_url +
          "/identities/" +
          this.identityId +
          "/profiles/" +
          item.profileId,
        method: "DELETE"
      });
      await this.init();
    }
  }
};
</script>

<style lang="scss" scoped></style>
