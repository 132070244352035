<template>
  <div style="height: 100px">{{ profileText }}</div>
</template>

<script>
export default {
  name: "IdentityProfileDetails",
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    profileText() {
      if (this.value == null) return this.$t("no_data");
      return JSON.stringify(this.value, undefined, 2);
    }
  },
  watch: {
    value: async function() {
      await this.update();
    }
  },
  created: async function() {
    this.init();
  },
  methods: {
    init: async function() {},
    update: async function() {}
  }
};
</script>

<style lang="scss" scoped></style>
